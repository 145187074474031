import React, { useState } from "react";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";
import moment from "moment";
import { Link } from "gatsby";
import { set } from "lodash";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper/core';

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

const Hero = ({ data }) => {
  const [checked, setChecked] = useState(false);
  SwiperCore.use([Autoplay,Pagination,Navigation]);

  let roastTime = "2021-08-07"; // yy, mm, dd

  let time = moment().to("08-07-2021");

  let today = moment().format("YYYY-MM-DD");

  let isTrue = moment(today).isBefore(roastTime, "day");


  return (
    <div className="hero">
      <Swiper style={{padding: '0'}} pagination={true} autoplay={{'delay': 5000, "disableOnInteraction": false}} >
        {data.map(item => {
          return (
            <SwiperSlide>
              <Img style={{ filter: "grayscale(100%)" }} className="heroImage" alt={item.title} fluid={item.fluid} />
            </SwiperSlide>
          )
        })}
      </Swiper>
     

      <div className="heroWrapper" style={{zIndex: '3'}}>
          <div className="heroDetails">
            <Fade left delay={500}>
              <h1 className="heroHeadline">The San Diego Roast</h1>
              <p className="heroText">
                <h2>The Roast  </h2><span style={{padding: '4px'}} ></span> {isTrue ? " is" : " was"} {time}
              </p>
            </Fade>
            <Fade bottom delay={1500}>
              <div className="cta-container">
                <button className="cta cta-main">
                  <Link style={{ textDecoration: "none" }} to="/rsvp">
                    RSVP
                  </Link>
                </button>
                {/* <button className="cta cta-secondary">
                  <Link style={{ textDecoration: "none" }} to="/about">
                    Learn More
                  </Link>
                </button> */}
              </div>
            </Fade>
          </div>
      </div>
    </div>
  );
};
export default Hero;
