import React, {useState} from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import "swiper/components/navigation/navigation.min.css"
import SwiperCore, {
  Navigation
} from 'swiper/core';

SwiperCore.use([Navigation]);

const Preview = ({ entries }) => {

// const entr = entries.sort((a, b) => (+b.node.year - +a.node.year))

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const randomArray = (max) => {
  return [getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max), getRandomInt(0, max)]
}


  return (
<div className="gallery">
<VerticalTimeline className='vertical-timeline' >
{entries.map(entry => {
    return (
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: '#fff', borderRadius: '20px'}}
    contentArrowStyle={{ borderRight: '7px solid  #fff' }}
    date={entry.node.year}
    iconStyle={{ background: '#4597cb', color: '#fff' }}
    // icon={<WorkIcon />}
  >
  {/* <h3 className="vertical-timeline-element-title">2017</h3> */}
    <Swiper navigation={true} className="mySwiper">
          {entry.node.assets.map((asset, index, arr) => {
            // if (index < 5 ) {
            if (randomArray(arr.length).includes(index) ) {
            return (
              <SwiperSlide style={{padding: '0 .5rem'}} >
                <Img style={{borderRadius: '20px'}} alt={asset.title} fluid={asset.fluid} />
              </SwiperSlide>
            )
          }
          })}
      {/* <SwiperSlide>Slide 1</SwiperSlide><SwiperSlide>Slide 2</SwiperSlide><SwiperSlide>Slide 3</SwiperSlide><SwiperSlide>Slide 4</SwiperSlide><SwiperSlide>Slide 5</SwiperSlide><SwiperSlide>Slide 6</SwiperSlide><SwiperSlide>Slide 7</SwiperSlide><SwiperSlide>Slide 8</SwiperSlide><SwiperSlide>Slide 9</SwiperSlide> */}
    </Swiper>
  <h3><Link to={`/gallery/${entry.node.year}`} >{entry.node.year}</Link></h3>

  </VerticalTimelineElement>
  )
  })}
</VerticalTimeline>
  <div style={{margin: '2rem'}}></div>
</div>
  )
}

export default Preview